import React from 'react'
import './preloader.sass'

const Preloader = (props) => {
  return (
    <div className='spinner'>
      <div className='double-bounce1' />
      <div className='double-bounce2' />
    </div>
  )
}

export default Preloader
